import { klaviyoAnalytics } from "../klaviyo";
import { facebookPixel } from "../facebookPixel";
import { tiktokPixel } from "../tiktokPixel";
import { googleAnalytics } from "../gtag";
import { pinterestAnalytics } from "../pinterest";
import { tripleWhalePixel } from "../triple-whale-pixel";
import { extractProductId } from "./lib/extract-product-id";
import { getSize, getColor, nodeToId } from "@components/Product/lib/product-utils";
import { depictEvent } from "@lib/depict-client";

function resolveProductFirstImage(productGroup, variant) {
  if (!productGroup || !variant) {
    return null;
  }

  // Loop through shopifyProducts to find the one that matches the variant
  try {
    for (const product of productGroup.shopifyProducts || []) {
      for (const _variant of product.productReferenceV2.variants) {
        if (_variant.id === variant.id) {
          return product.media.edges[0].node.image.originalSrc;
        }
      }
    }
  } catch (error) {
    return null;
  }

  // If no match found, return null
  return null;
}

export function addToCart({ productGroup, variant, checkout }) {

  const productFirstImage = resolveProductFirstImage(productGroup, variant);

  googleAnalytics("event", "add_to_cart", {
    currency: "CAD",
    value: checkout.subtotalPriceV2.amount,
    items: [
      {
        id: variant.sku,
        name: variant.title,
        variant: getColor(variant),
        brand: variant.vendor,
        size: getSize(variant),
        category:
          productGroup.shopifyProducts[0].productReferenceV2?.store.productType ??
          productGroup.shopifyProducts[0].type,
        price: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
        quantity: "1",
      },
    ],
  });

  facebookPixel("track", "AddToCart", {
    content_type:
      productGroup.shopifyProducts[0].productReferenceV2?.store.productType ??
      productGroup.shopifyProducts[0].type,
    content_ids: [variant.sku],
    content_name: variant.title,
    content_color: getColor(variant),
    content_size: getSize(variant),
    currency: "CAD",
    value: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
  });


  checkout.lineItems.edges.forEach((lineItem) => {
    tripleWhalePixel('AddToCart', {
      item: lineItem.node.variant.id,
      q: lineItem.node.quantity,
      token: checkout.id,
    });
  });

  klaviyoAnalytics("Added to Cart", {
    value: checkout.subtotalPriceV2.amount,
    AddedItemProductName: productGroup.title,
    AddedItemProductID: variant.id,
    AddedItemSKU: variant.sku,
    AddedItemPrice: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
    AddedItemImageURL: productFirstImage,
    AddedItemType:
      productGroup.shopifyProducts[0].productReferenceV2?.store.productType ??
      productGroup.shopifyProducts[0].type,
    AddedItemQuantity: 1,
    ItemNames: productGroup.title,
    CheckoutURL: checkout ? checkout.webUrl : null,
    Items: [
      {
        ProductName: productGroup.title,
        ProductID:
          productGroup.shopifyProducts[0].productReferenceV2?.store.id ??
          productGroup.shopifyProducts[0].id,
        SKU: variant.sku,
        Categories: [
          productGroup.shopifyProducts[0].productReferenceV2?.store.productType ??
            productGroup.shopifyProducts[0].type,
        ],
        URL: window.location.href,
        Brand: productGroup.shopifyProducts[0].vendor,
        Price: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
      },
    ],
  });

  pinterestAnalytics("track", "AddToCart", {
    value: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
    product_id: extractProductId(
      productGroup.shopifyProducts[0].productReferenceV2?.store.id ??
        productGroup.shopifyProducts[0].id
    ),
    order_quantity: 1,
    currency: "CAD",
  });

  tiktokPixel("AddToCart", {
    content_id: extractProductId(
      productGroup.shopifyProducts[0].productReferenceV2?.store.id ??
        productGroup.shopifyProducts[0].id
    ),
    content_type: "product",
    content_name: variant.title,
    content_category:
      productGroup.shopifyProducts[0].productReferenceV2?.store.productType ??
      productGroup.shopifyProducts[0].type,
    quantity: 1,
    price: variant.priceV2 ? variant.priceV2.amount : variant.presentmentPrices[0].price.amount,
    value: checkout.subtotalPriceV2.amount,
    currency: "CAD",
  });

  depictEvent('addToCart', extractProductId(
    productGroup.shopifyProducts[0].productReferenceV2?.store.id ??
      productGroup.shopifyProducts[0].id
  ));

}

export function resolveSpecialText(product, onSale, t, isMobile) {
  const displayedText = [];
  const { availableForSale, tags } = product;

  const newTag = tags && tags.find((tag) => tag.toLowerCase() === "new");
  const bundle_2_15 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-bedding-15" || tag.toLowerCase() === "bundle-bath-15");
  const bundle_3_15 = tags && tags.find((tag) => tag.toLowerCase() === "buy3get15");
  const bundle_5_15 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-socks-15");
  // const bedding = tags && tags.find((tag) => tag.toLowerCase() === "bedding-20");
  const buy_3_100 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-crew-3" || tag.toLowerCase() === "bundle-tanks-3");
  const buy_2_100 = tags && tags.find((tag) => tag.toLowerCase() === "bundle-heavycrew-2");
  const best_seller = tags && tags.find((tag) => tag.toLowerCase() === "best-seller-pdp");
  // const active_promo = tags && tags.find((tag) => tag.toLowerCase() === "last-chance");

  // ARRAY OVERRIDES HERE
  if (product.tags.find((tag) => tag === "pre-launch")) {
    return [t("coming-soon")];
  }

  if (!availableForSale) {
    return [t("sold-out")];
  }


  if (availableForSale && newTag) { displayedText.push(t("new")); }
  if (availableForSale && bundle_2_15) { displayedText.push(t("Buy 2+, Save 15%"));}
  if (availableForSale && bundle_5_15) { displayedText.push(t("Buy 5+, Save 15%"));   }
  if (availableForSale && bundle_3_15) { displayedText.push(t("Buy 3+, Get 15% Off")); }
  if (availableForSale && buy_3_100) { displayedText.push(t("Buy 3 for $100")); }
  if (availableForSale && buy_2_100) { displayedText.push(t("Buy 2 for $100")); }
  // if (availableForSale && bedding) { displayedText.push(t("20% Off")); }
  if (best_seller && onSale) { displayedText.push(t("Best Seller")); } 
  if (availableForSale && onSale) { displayedText.push(t("Sale")); } 
  // if (availableForSale && active_promo) { displayedText.push(t("Additional 20% Off")); }

  // displayedText.push(t("20% Off Everything"));
  return displayedText; 
}
  
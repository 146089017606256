export const KLAVIYO_API_KEY = "LMZu4j";

export function klaviyoAnalytics(event, payload) {
  try {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
      //@ts-ignore
      if (event === "identify") {
        klaviyo.identify(payload);
      } else {
        klaviyo.track(event, payload);
      }
      console.log("[klaviyo-tracking]: event production ", event, payload);
    } else {
      console.log("[klaviyo-tracking]: event development ", event, payload);
    }
  } catch (err) {
    console.log("[klaviyo-tracking]: error ", err);
    console.log("[klaviyo-tracking]: error payload ", event, payload);
  }
}

export function tripleWhalePixel(event, payload) {
    try {
        if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
            TriplePixel(event, payload);
            console.log("[triple-whale-tracking]: event", event, checkout);
        } else {
            console.log("[triple-whale-tracking]: event", event, checkout);
        }
    } catch (e) {
        console.log("[triple-whale-tracking]: error", event, checkout, e);
    }
}
import * as currencyService from "@services/format/currency";

export function resolveSubtotal(cart) {
  if (cart && cart.cost.subtotalAmount.amount > 0)
    return currencyService.formatPrice(
      cart.cost.subtotalAmount.amount,
      cart.cost.subtotalAmount.currencyCode
    );

  return null;
}

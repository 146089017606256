export function updateItem(currentItems, incomingItem) {
  let updatedItems = currentItems.map((lineItem) => {
    if (lineItem.id === incomingItem.itemId) {
      lineItem.quantity = incomingItem.quantity;
    }
    return [
      {
        itemId: lineItem.id,
        variantId: lineItem.merchandise.id,
        quantity: lineItem.quantity,
      }
    ];
  });
  updatedItems = [].concat(...updatedItems);

  return updatedItems;
}


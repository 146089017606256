import { googleAnalytics } from "../gtag";
import { klaviyoAnalytics } from "../klaviyo";
import { facebookPixel } from "../facebookPixel";
import { getSize, getColor } from "@components/Product/lib/product-utils";
import { pinterestAnalytics } from "../pinterest";
import { tiktokPixel } from "../tiktokPixel";
import { extractProductId } from "./lib/extract-product-id";
import { depictEvent } from "@lib/depict-client";

export function viewItem({ productGroup, variant }) {
  googleAnalytics("event", "view_item", {
    items: [
      {
        id: variant.sku,
        name: variant.title,
        variant: getColor(variant),
        category: productGroup.shopifyProducts[0].productReferenceV2.store.productType,
        size: getSize(variant),
        price: variant.priceV2.amount,
        currency: variant.priceV2.currencyCode,
        quantity: 1,
      },
    ],
  });

  facebookPixel("track", "ViewContent", {
    content_type: productGroup.shopifyProducts[0].productReferenceV2.store.productType,
    content_ids: [variant.sku],
  });

  klaviyoAnalytics("Viewed Product", {
    ProductName: productGroup.title,
    ProductID: productGroup.shopifyProducts[0].productReferenceV2.store.gid,
    SKU: variant.sku,
    Categories: [productGroup.shopifyProducts[0].productReferenceV2.store.productType],
    URL: window.location.href,
    Brand: productGroup.shopifyProducts[0].vendor,
    price: variant.priceV2.amount,
  });


  pinterestAnalytics("track", "pagevisit", {
    product_id: extractProductId(productGroup.shopifyProducts[0].productReferenceV2.store.gid),
  });

  tiktokPixel("ViewContent", {
    content_type: "product",
    content_id: variant.sku,
  });

  depictEvent(
    "setProductId",
    extractProductId(productGroup.shopifyProducts[0].productReferenceV2.store.gid)
  );
}

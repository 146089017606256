import { TOTE } from "../Drawer";

export function addItem(currentItems, incomingItem) {
  let alreadyInCart = false;
  // Get current items
  let updatedItems = currentItems.map(lineItem => {
    // Check if this line item matches the incoming item
    if (lineItem.merchandise.id === incomingItem.id) {
      // Update the current item in the cart
      alreadyInCart = true;
      lineItem.quantity += 1;
    }

    return {
      itemId: lineItem.id,
      variantId: lineItem.merchandise.id,
      quantity: lineItem.quantity
    };
    // Return unchanged line item
  });

  if (!alreadyInCart) {
    // Add the item to the checkout
    let itemToAdd = {
      variantId: incomingItem.id,
      quantity: 1,
    };
    
    return { updatedItems: null, newItem: itemToAdd };
  }

  return { updatedItems, newItem: null };
}

import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import PropTypes from "prop-types";
import { Image } from "./Image";
import { Colour } from "./Colour";
import { Size } from "./Size";
import { Quantity } from "./Quantity";
import { Price } from "./Price";
import { FinalSale } from "./FinalSale";
import { Linkable } from "./Linkable";
import { formatTitle } from "@lib/formatString";
import { googleAnalytics } from "@lib/gtag";
import { formatMerchandisePrice } from "./lib/format-merchandise-price";
import { useCurrencyState } from "@hooks/useCurrency";
import { resolveSpecialNotes } from "./lib/resolve-special-notes";
import styles from "./styles/lineitem.module.css";
import { TOTE } from "../Drawer";

export function LineItem({ item, onItemUpdate, loading, handleCloseDrawer }) {
  const { t } = useTranslation("cart");
  const { currency } = useCurrencyState();
  const [cachedPrice, setCachedPrice] = useState(null);
  const [cachedCompareAtPrice, setCachedCompareAtPrice] = useState(null);
  const [cachedDiscount, setCachedDiscount] = useState(null);
  const [cachedAmount, setCachedAmount] = useState(null);
  const [currencyInitialized, setCurrencyInitialized] = useState(false);
  const finalSale = item.merchandise.product.tags.indexOf("final-sale") > -1;

  const [quantity, setQuantity] = useState(item.quantity);
  const [productUrl, setProductUrl] = useState({
    slug: item.merchandise.product.handle,
    colour: item.merchandise.title.split(" / ")[0],
    size: item.merchandise.title.split(" / ")[1], 
  });

  // Ensure price is calculated once currency is available
  useEffect(() => {
    if (!currencyInitialized && currency) {
      setCurrencyInitialized(true);
    }
  }, [currency]);

  // Set price on component mount and when currency changes
  useEffect(() => {
    if (currencyInitialized && item.merchandise.price.currencyCode === currency) {
      const { price, compareAtPrice, discount } = formatMerchandisePrice(
        item.merchandise,
        currency,
        item.quantity,
        item.discountAllocations
      );

      console.log('useCheckout item.merchandise', price, compareAtPrice, discount);

      setCachedPrice(price);
      setCachedCompareAtPrice(compareAtPrice);
      setCachedDiscount(discount);
      setCachedAmount(Number(price.replace(/[^\d.-]/g, "")));
      setProductUrl({
        slug: item.merchandise.product.handle,
        colour: item.merchandise.title.split(" / ")[0],
        size: item.merchandise.title.split(" / ")[1], 
      });
    }
  }, [currency, item.merchandise, item.discountAllocations, item.quantity, currencyInitialized]);

  // useEffect(() => {
  //   if (cachedPrice) {
  //     const numericPrice = Number(cachedPrice.replace(/[^\d.-]/g, ""));
  //     const totalAmount = numericPrice * quantity;

  //     setCachedAmount(totalAmount);
  //   }
  // }, [quantity, cachedPrice]);

  const updateItem = (data) => {
    if (data.quantity === 0) {
      googleAnalytics("event", "remove_from_cart", {
        ecommerce: {
          items: [
            {
              item_id: item.merchandise.id,
              item_name: item.merchandise.title,
              price: cachedAmount,
              currency,
            },
          ],
        },
      });
    }
    onItemUpdate({ itemId: item.id, variantId: item.merchandise.id, ...data });
  };

  const handleBlur = () => {
    const val = Number(quantity);
    if (val !== quantity) {
      updateItem({ quantity: val });
    }
  };

  const increaseQuantity = (n = 1) => {
    const val = Number(quantity) + n;

    if (Number.isInteger(val) && val >= 0) {
      setQuantity(val);
      updateItem({ quantity: val });
    }
  };

  const hideToteElements = item.title === TOTE.title;

  return (
    <article className={styles.container}>
      <Linkable query={productUrl} handleCloseDrawer={handleCloseDrawer}>
        <img src={item.merchandise.image.url} alt={item.merchandise.title} />
      </Linkable>
      <div className={styles.content}>
        <Linkable query={productUrl} handleCloseDrawer={handleCloseDrawer}>
          <h4>{formatTitle(item.merchandise.title)}</h4>
        </Linkable>
        <ul className={styles.list}>
          <Colour variant={item.merchandise} />
          <Size variant={item.merchandise} />
          <Quantity
            value={quantity}
            max={item.merchandise.quantityAvailable}
            disabled={loading}
            onIncrease={increaseQuantity}
            onBlur={handleBlur}
            hide={hideToteElements}
          />
          {cachedPrice && (
            <Price price={cachedAmount.toFixed(2)} compareAtPrice={cachedCompareAtPrice} />
          )}
        </ul>
        <FinalSale finalSale={finalSale} discount={cachedDiscount} />
        {resolveSpecialNotes(item.attributes)}
        {!hideToteElements && (
          <button onClick={() => updateItem({ quantity: 0 })} className={styles.button}>
            {t("remove-item")}
          </button>
        )}
      </div>
    </article>
  );
}

LineItem.propTypes = {
  item: PropTypes.object.isRequired,
  onItemUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

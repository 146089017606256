import { CURRENCY_TO_COUNTRY_CODE } from "@lib/constants";
import graphqlFetch from "@lib/graphql-fetch";
import { logError } from "@lib/logger";

const CartFields = `
  fragment CartFields on Cart {
    id
    checkoutUrl
    attributes {
      key
      value
    }
    cost {
      checkoutChargeAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
    }
    discountCodes {
      applicable
      code
    }
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          cost {
            amountPerQuantity {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              sku
              title
              quantityAvailable
              price {
                amount
                currencyCode
              }
              compareAtPrice {
                amount
                currencyCode
              }
              selectedOptions {
                name
                value
              }
              image {
                altText
                url(transform: {maxWidth: 100, preferredContentType: JPG})
              }
              product {
                tags
                handle
                id
              }
            }
          }
        }
      }
    }
  }
`;

const FetchCart = `
  query FetchCart($id: ID!) {
    node(id: $id) {
      ...CartFields
    }
  }
  ${CartFields}
`;

const CartCreate = `
  mutation CartCreate($input: CartInput!,$countryCode: CountryCode) @inContext(country: $countryCode) {
    cartCreate(input: $input) {
      userErrors {
        code
        field
        message
      }
      cart {
        ...CartFields
      }
    }
  }
  ${CartFields}
`;

const ReplaceLineItems = `
  mutation CartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      userErrors {
        code
        field
        message
      }
      cart {
        ...CartFields
      }
    }
  }
  ${CartFields}
`;

const cartApplyDiscountMutation = `
  mutation cartDiscountCodesUpdate($cartId: ID!, $discountCodes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        id
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;

const cartLinesAddMutation = `
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartFields
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CartFields}
`;


export async function getCart(id) {
  if (id) {
    const checkout = await graphqlFetch(FetchCart, {
      variables: {
        id,
      },
    });
    return checkout.node;
  }
  return null;
}

export async function setLineItems(checkout, lineItems, currency) {
  let updatedCheckout = null;
  if (checkout) {
    updatedCheckout = await graphqlFetch(ReplaceLineItems, {
      variables: {
        cartId: checkout.id,
        lines: lineItems.map(line => ({
          id: line.itemId,
          merchandiseId: line.variantId,
          quantity: line.quantity,
          attributes: line.attributes
        }))
      },
    });
    return updatedCheckout.cartLinesUpdate;
  }
  try {
    updatedCheckout = await graphqlFetch(CartCreate, {
      variables: {
        input: {
        lines: lineItems.map(item => ({
          merchandiseId: item.variantId,
          quantity: item.quantity,
          attributes: item.customAttributes
        })),
        attributes: [
          {
            key: "defaultorderstatus",
            value: "UnConfirmed",
          },
        ],
      },
      countryCode: CURRENCY_TO_COUNTRY_CODE[currency],
    },
  });
  } catch (err) {
    console.log('CartCreate error');
    return null;
  }

  return updatedCheckout.cartCreate;
}

export async function addCartLine(cart, lineItem, currency) {
  if (!cart) {
    try {
      const updatedCheckout = await graphqlFetch(CartCreate, {
          variables: {
            input: {
            lines: {
              merchandiseId: lineItem.variantId,
              quantity: lineItem.quantity,
              attributes: lineItem.customAttributes
            }, 
            attributes: [
              {
                key: "defaultorderstatus",
                value: "UnConfirmed",
              },
            ],
          },
          countryCode: CURRENCY_TO_COUNTRY_CODE[currency],
        },
      });
      return updatedCheckout.cartCreate;
    } catch (err) {
      console.log('CartCreate error');
      console.log('CartCreate err', err);
      return null;
    }
  
  }
  try {
    const updatedCart = await graphqlFetch(cartLinesAddMutation, {
      variables: {
        cartId: cart.id,
      lines: {
        merchandiseId: lineItem.variantId,
        quantity: lineItem.quantity,
        attributes: lineItem.customAttributes
      },
    },
  });

    return updatedCart.cartLinesAdd;
  } catch (err) {
    console.log('cartLinesAddMutation error');
    return null;
  }
}

export async function cartApplyDiscount(discountCode, cartId) {
  try {
    await graphqlFetch(cartApplyDiscountMutation, {
      variables: { 
        cartId, 
        discountCodes: [discountCode] 
      },
    });
    return true;
  } catch (err) {
    logError(err);
    return false;
  }
}

const LOCALE = typeof window !== "undefined" ? window.navigator.language : "en-US";

const formatDiscount = new Intl.NumberFormat(LOCALE, {
  style: "percent",
});

export function formatPrice({ amount, currencyCode }, quantity) {
  const formatCurrency = new Intl.NumberFormat(LOCALE, {
    style: "currency",
    currency: currencyCode ?? "CAD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatCurrency.format(amount * quantity).replace(".00", "");
}

function computeDiscount(hasDiscount, discounts, compareAtAmount, amount) {
  let discountAmount = null;
  let discount = null;
  if (hasDiscount) {
    if (compareAtAmount !== 0) {
      discountAmount = discounts + (compareAtAmount - amount);
      discount = formatDiscount.format((discounts + (compareAtAmount - amount)) / compareAtAmount);
    } else if (amount !== 0) {
      discountAmount = discounts;
      discount = formatDiscount.format(discounts / amount);
    }
  }

  return { discountAmount, discount };
}

export function resolvePrice(merchandise, currency) {
  if (merchandise.price) {
    const compareAtPrice = merchandise.compareAtPrice ? merchandise.compareAtPrice.amount : 0;
    return {
      price: { amount: merchandise.price.amount, currencyCode: currency },
      compareAtPrice: { amount: compareAtPrice, currencyCode: currency },
    };
  }

  return {
    price: { amount: "0.0", currencyCode: "CAD" },
    compareAtPrice: { amount: "0.0", currencyCode: "CAD" },
  };
}

export function formatMerchandisePrice(
  merchandise,
  currency = "CAD",
  quantity,
  discountAllocations = []
) {
  // let { price: variant.priceV2, compareAtPrice } = getPrice(presentmentPrices, currency);

  let { price, compareAtPrice } = resolvePrice(merchandise, currency);
  
  const discounts = discountAllocations.reduce(
    (sum, { allocatedAmount }) => (sum = sum + Number(allocatedAmount.amount)),
    0
  );

  let amount = Number(price.amount);
  let compareAtAmount = compareAtPrice ? Number(compareAtPrice.amount) : amount;
  let hasDiscount = compareAtAmount > amount;

  compareAtPrice = hasDiscount ? formatPrice(compareAtPrice, quantity) : null;

  if (!compareAtPrice && discounts > 0) {
    compareAtPrice = formatPrice(price, quantity);
    hasDiscount = true;
  }

  price = formatPrice({ amount: amount * quantity - discounts, currencyCode: currency }, 1);

  amount = amount * quantity; 
  compareAtAmount = compareAtAmount * quantity;

  const { discountAmount, discount } = computeDiscount(
    hasDiscount,
    discounts,
    compareAtAmount,
    amount
  );

  return { price, compareAtPrice, discount, discountAmount, amount, compareAtAmount };
}


import React from "react";
import { useTranslation } from "next-i18next";
import PropTypes from "prop-types";
import { Button } from "@components/Button";
import { resolveFooterNotice } from "./lib/resolve-footer-notice";
import styles from "./styles/footer.module.css";


export function CartDrawerFooter({ handleSubmit, loading, location, total }) {
  const { t } = useTranslation("cart");
  const inNorthAmerica = resolveFooterNotice(location, total, t);
  return (
    <footer className={styles.footer}>
      {/* <CartPackagingOptOut /> */}
      {inNorthAmerica}
      <Button
        handleClick={handleSubmit}
        isDisabled={loading}
        description="checkout"
        descriptionText="Click to go to the checkout page"
      >
        {loading ? t("checkout-button-loading") : t("checkout-button")}
      </Button>
    </footer>
  );
}

CartDrawerFooter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
};

import * as currencyService from "@services/format/currency";

export function resolveTotal(cart) {
  if (cart && cart.cost.checkoutChargeAmount.amount > 0)
    return currencyService.formatPrice(
      cart.cost.checkoutChargeAmount.amount,
      cart.cost.checkoutChargeAmount.currencyCode
    );

  return null;
}
